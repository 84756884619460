import React, { } from 'react';
import { Redirect } from 'react-router-dom';

const Bouncer = props => {
    const state = props.location.state;

    return <Redirect to={{
        pathname: state.returnPath,
        state: state
    }} />;
}

export default Bouncer
