import React, { useContext, useState, useEffect } from 'react';
import * as EmailValidator from 'email-validator';
import { Link, Redirect } from 'react-router-dom';
import './LogIn.css';
import { fireAuth } from '../../../Fire';
import { AuthContext } from './../../../Auth';

const LogIn = ({ history }) => {
    const [emailValue, setEmailValue] = useState('');
    const [emailValid, setEmailValid] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [passwordValid, setPasswordValid] = useState('');
    const [verified, setVerified] = useState(true);
    const [loginErrorDisplay, setLoginErrorDisplay] = useState('');

    const isValidEmail = email => {
        return EmailValidator.validate(email);
    }

    useEffect(() => {
        // verify form
        if (emailValid === '' && passwordValid === '') {
            setVerified(true);
        } else {
            setVerified(false);
        }
    }, [emailValid, passwordValid]);

    const handleEmailChange = event => {
        let newValue = event.target.value;
        setEmailValue(newValue);
        if (isValidEmail(newValue)) {
            setEmailValid('');
        } else {
            setEmailValid('is-invalid');
        }
    }

    const handlePasswordChange = event => {
        let newValue = event.target.value;
        setPasswordValue(newValue);
        if (passwordValue.length > 32) {
            setPasswordValid('is-invalid');
        } else {
            setPasswordValid('');
        }
    }

    const handleLogin = event => {
            event.preventDefault();
            // console.log(fireAuth)
            fireAuth.signInWithEmailAndPassword(emailValue, passwordValue)
            .then((user) => {
                // Signed in 
                history.push('/');
            })
            .catch((error) => {
                console.log("error code: " + error.code);
                console.log("error message: " + error.message);
                console.log("email: " + emailValue);
                if (error.code === "auth/user-not-found") {
                    setLoginErrorDisplay('A user with that email doesn\'t exist');
                } else if (error.code === "auth/wrong-password") {
                    setLoginErrorDisplay('Incorrect password');
                } else if (error.code === "auth/network-request-failed") {
                    setLoginErrorDisplay('Can\'t connect to network');
                }
            });
    };

    const { currentUser } = useContext(AuthContext);
    if (currentUser) {
        return <Redirect to='/' />;
    }
    
    return (
        <div className="sign-in-page" >
            {/* <img className="sign-in-background-img" src="/img/climbing/climbing-group.jpg" alt="climbing"/> */}
            <div data-testid='log-in' className="sign-in-card">
                <div className="sign-in-card-wrapper">
                    <h2 className="sign-in-header">Log In</h2>
                        <form onSubmit={handleLogin}>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="addon-wrapping">
                                        <i className='bi-envelope' />
                                    </span>
                                </div>
                                <input onChange={handleEmailChange} type="email" id="emailInput" className={"form-control " + emailValid} placeholder="email" aria-label="email" aria-describedby="addon-wrapping" required/>
                                <div className="invalid-feedback">Please provide a valid email</div>
                            </div>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="addon-wrapping">
                                        <i className='bi-key' />
                                    </span>
                                </div>
                                <input onChange={handlePasswordChange} type="password" id="passwordInput" className={"form-control " + passwordValid} placeholder="password" aria-label="password" aria-describedby="addon-wrapping" required/>
                                <div className="invalid-feedback">Password is too long</div>
                            </div>
                            <div className="sign-in-button-wrapper">
                                <button data-testid='login-button' onClick={handleLogin} type="submit" className="btn btn-primary btn-lg" disabled={!verified}>Log In</button>
                            </div>
                            <div className="login-errors">
                                <p className={"login-error-message"}>{loginErrorDisplay}</p>
                            </div>
                            <div className="sign-in-links">
                                <Link to="/">Forgot password?</Link>
                                <br/>
                                <Link to="/create-account">Create Account</Link>
                            </div>
                        </form>
                </div>
            </div>
        </div>
    );
}

export default LogIn;