import React, { useState, useEffect } from 'react';
import NavBar from './../../nav-bar/NavBar';
import Footer from '../../footer/Footer';
import Loading from '../loading/Loading';
import { rtDatabase, fireAuth } from '../../../Fire';
import { Redirect } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { jsPDF } from 'jspdf';
import { Button } from 'react-bootstrap';
import BarChart from './BarChart';
import SaModal from './SaModal';
import './Analysis.css';

const Analysis = props => {
    const surveyName = props.location === undefined ? props.surveyName : props.location.state.surveyName;
    const normalize = props.location === undefined ? props.normalize : props.location.state.normalize;
    const uid = props.uid === undefined ? fireAuth.currentUser.uid : props.uid;
    const sharing = props.location === undefined ? props.sharing : props.location.state.sharing;
    const shareName = props.location === undefined ? props.shareName : props.location.state.shareName;
    const bouncePath = props.bouncePath === undefined ? '/analysis' : props.bouncePath;
    const [data, setData] = useState('');
    const [analysis, setAnalysis] = useState('');
    const [errorObj, setErrorObj] = useState('');
    const [max, setMax] = useState(undefined);
    const [redirect, setRedirect] = useState('');
    const [saModalData, setSaModalData] = useState({
        show: false,
        data: [],
        title: ''
    });

    useEffect(() => {
        console.log("first useEffect")
        rtDatabase.ref('userData/' + uid + '/surveys/' + surveyName).once('value').then(snapshot => {
            setData(snapshot.val());
        }).catch(error => {
            setErrorObj(error);
        })
    }, [])

    useEffect(() => {
        console.log("second useEffect")
        if (data !== '' && data.entries !== undefined) {
            makeAnalysis();
        }
    }, [data])

    const makeAnalysis = () => {
        console.log("analyzing")
        let max = 0;
        let temp = {}
        Object.keys(data.questions).forEach(qnum => {
            if (data.questions[qnum].type === 'Ranking') {
                temp[qnum] = {
                    1: 0,
                    2: 0,
                    3: 0,
                    4: 0,
                    5: 0,
                    6: 0,
                    7: 0,
                    8: 0,
                    9: 0,
                    10: 0,
                    11: 0
                }
            } else if (data.questions[qnum].type === 'Short Answer') {
                temp[qnum] = {}
            }
        })

        Object.keys(data.entries).forEach(entrynum => {
            Object.keys(data.entries[entrynum]).forEach(qnum => {
                if (typeof data.entries[entrynum][qnum] === 'string') {
                    // console.log("qnum at string: " + qnum)
                    // this is a Ranking question
                    temp[qnum][data.entries[entrynum][qnum]] += 1;
                    // increase max for graph range normalization
                    if (temp[qnum][data.entries[entrynum][qnum]] > max) {
                        max = temp[qnum][data.entries[entrynum][qnum]];
                    }
                } else {
                    // this is a Short Answer question
                    if (data.entries[entrynum][qnum]["0"] !== "") {
                        Object.values(data.entries[entrynum][qnum]).forEach(entry => {
                            let entryNorm = normalizeEntry(entry);
                            if (entry !== '') {
                                if (Object.keys(temp[qnum]).includes(entryNorm)) {
                                    // entry already exists in object
                                    temp[qnum][entryNorm] += 1;
                                } else {
                                    temp[qnum][entryNorm] = 1;
                                }
                            }
                        })
                        // temp[qnum] = temp[qnum].concat(Object.values(data.entries[entrynum][qnum]))
                    }
                }
            })
        })
        console.log("analysis temp afterwards:")
        console.log("max: " + max)
        console.log(temp);
        max = Math.ceil(max / 20) * 20;
        setMax(max);
        setAnalysis(temp);
    }

    const normalizeEntry = entry => {
        // lowercase everything
        let regex = /[A-Z]/g;
        let newStr = entry.replace(regex, match => {
            return match.toLowerCase();
        });

        // capitalize first letter
        newStr = newStr.charAt(0).toUpperCase() + newStr.slice(1);

        // capitalize first letters of all words
        let regex2 = /\s[a-z]/g;
        newStr = newStr.replace(regex2, match => {
            return match.toUpperCase();
        })

        // lowercase 'and's
        let regex3 = /And/g;
        newStr = newStr.replace(regex3, 'and');

        // get rid of trailing whitespace
        let regex4 = /\s$/;
        newStr = newStr.replace(regex4, '');

        // get rid of extra consecutive spaces
        let regex5 = /\s{2,}/g;
        newStr = newStr.replace(regex5, ' ');

        return newStr;
    }

    const handlePageDownload = () => {
        console.log("downloading");
        let doc = new jsPDF();

        let canvas = document.getElementById('analysis-page-wrapper');
        
        doc.html(canvas, {
           callback: doc2 => {
                doc2.scale(0.1, 0.1)
                doc2.save(surveyName + '.pdf');
           },
           html2canvas: {
               scale: 0.14,
           }
        });
    }

    const handleGetShareLink = () => {
        let newName = fixName(surveyName)
        let user = fixName(fireAuth.currentUser.displayName);
        let url = '';
        if (window.location.hostname === 'localhost') {
            url = "http://localhost:3000/share/" + uid + '/' + newName + '/' + user;
            console.log(url);
            copyStringToClipboard(url);
            alert("Shareable link copied to clipboard.")
        } else {
            url = 'http://' + window.location.hostname + '/share/' + uid + '/' + newName + '/' + user;
            console.log(url);
            copyStringToClipboard(url);
            alert("Shareable link copied to clipboard.")
        }
    }

    const handleNormalize = () => {
        setRedirect({
            path: '/bouncer',
            state: {
                surveyName: surveyName,
                normalize: !normalize,
                sharing: sharing,
                returnPath: bouncePath,
                shareName: shareName
            }
        })
    }

    const copyStringToClipboard = str => {
        // Create new element
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = str;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
    }     

    const fixName = name => {
        return name.replace(/\s/g, '%20');
    }

    const sortShortAnswer = qObj => {
        // sort qObj
        let pairArray = Object.entries(qObj);
        pairArray.sort((a, b) => {
            return b[1] - a[1];
        })
        return pairArray;
    }

    const filterSaResponses = pairArray => {
        let keys = [];
        let values = [];
        pairArray.forEach((pair, i) => {
            // only take the 10 greatest entries
            if (i < 10 && pair[1] > 1) {
                keys.push(pair[0]);
                values.push(pair[1]);
            }
        })
        return {
            keys: keys,
            values: values
        }
    }

    const handleInfoClick = (id, pairArray, title) => {
        setSaModalData({
            show: true,
            data: pairArray,
            title: title
        })
    }

    // loading
    if (data === '' || (analysis === '' && data.entries !== undefined)) {
        return <Loading />
    }

    // redirect to error screen if appropriate
    if (errorObj !== '') {
        return <Redirect to={{
            pathname: '/error',
            state: { code: errorObj.code, message: errorObj.message }
        }} />;
    }

    if (redirect !== '') {
        return <Redirect to={{
            pathname: redirect.path,
            state: redirect.state
        }} />;
    }

    let charts;
    if (data.entries === undefined || data === '') {
        charts = <></>
    } else {
        charts = Object.keys(analysis).map(qnum => {
            let qObj = analysis[qnum]
            let keys = [];
            let values = [];
            let type = '';
            let average = 0;
            let pairArray = [];
            let qTitle = data.questions[qnum].question;
            if (Object.keys(qObj)[0] !== "1" && Object.keys(qObj)[1] !== "2") {
                // is short answer question
                pairArray = sortShortAnswer(qObj);
                let chartData = filterSaResponses(pairArray);
                keys = chartData.keys;
                values = chartData.values;
                type = 'Short Answer';
            } else {
                // is ranking question
                keys = Object.keys(qObj).filter(key => key !== '')
                values = Object.values(qObj)
                type = 'Ranking';
                console.log("values")
                console.log(values);
                let sum = 0;
                let count = 0;
                values.forEach((value, i) => {
                    if (i < 11) {
                        sum += (i + 1) * value;
                        count += value;
                    }
                })
                average = Math.round(sum / count * 100) / 100;
                console.log(average);
            }
            return (
                <div key={qnum} className="analysis-bar-graph-wrapper">
                    <div className='analysis-bar-graph-title-wrapper'>
                        <h2 className="analysis-bar-graph-title">{qTitle}</h2>
                        <div className={type === 'Short Answer' ? '' : 'd-none'}>
                            <div className='info-circle btn-has-screen'>
                                <button id={qnum} onClick={() => { handleInfoClick(qnum, pairArray, qTitle) }} className='btn-screen info-btn-screen'/>
                                <div className='info-i'>i</div>
                            </div>
                        </div>
                    </div>
                    <div className={type === 'Ranking' ? '' : 'd-none'}>
                        <p className='analysis-bar-graph-stats'>average: {average}</p>
                    </div>
                    <BarChart
                        labels={keys}
                        data={values}
                        title={"Question " + qnum}
                        type={type}
                        max={max}
                        scale={normalize}
                    />
                </div>
            )
        })
    }
    
    return (
        <div>
            <NavBar />
            <div className={sharing ? '' : 'd-none'}>
                <p className="share-name-text">Shared with you by {shareName}</p>
            </div>
            <div className="analysis-page-wrapper" id="analysis-page-wrapper">
                <div className="analysis-header">
                    <div className="analysis-header-text">
                        <h1 className="analysis-title">{surveyName}</h1>
                        <p className="analysis-entry-count">Entry Count: {data.entryCount}</p>
                    </div>
                    <div className="analysis-header-btns-container">
                        {/* <div className="analysis-header-download-btn-wrapper">
                            <Button onClick={handlePageDownload} variant="secondary">
                                <i className="bi-download"/>
                            </Button>
                        </div> */}
                        <div className={sharing ? 'd-none' : ''}>
                            <div className="analysis-header-download-btn-wrapper">
                                <Button title="Share Link" onClick={handleGetShareLink} variant="primary">
                                    <i className="bi-box-arrow-up"/>
                                </Button>
                            </div>
                        </div>
                        <div className="analysis-header-download-btn-wrapper">
                            <Button title="Share Link" onClick={handleNormalize} variant="secondary">
                                {normalize ? 'Normalize' : 'De-Normalize'}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="analysis-chart-container" id="chart-container">
                    {charts}
                </div>
            </div>
            <Footer />
            <SaModal
                onHide={() => { setSaModalData({
                    ...saModalData,
                    show: false
                }) }}
                show={ saModalData.show }
                data={ saModalData.data } 
                title={ saModalData.title }
            />
        </div>
    )
}

export default Analysis