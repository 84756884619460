import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'react-bootstrap';
import './SaModal.css';

const SaModal = props => {
    console.log(props.data)
    return (
        <Modal centered show={props.show} onHide={props.onHide} size="lg">
            <Modal.Header closeButton>
                <h1 className='sa-modal-title'>{props.title}</h1>
            </Modal.Header>
            <Modal.Body>
                <div className='sa-modal-table-wrapper'>
                    <Table>
                        <thead>
                            <tr>
                                <th>Response</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.map(pair => {
                                return (
                                    <tr>
                                        <td>{pair[0]}</td>
                                        <td>{pair[1]}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            </Modal.Body>
        </Modal>

    )
}

export default SaModal;