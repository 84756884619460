import React, { useEffect, useState } from 'react';
import { fireAuth } from './Fire';
import Loading from './components/pages/loading/Loading';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children, value }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    let mounted = true;
    fireAuth.onAuthStateChanged((user) => {
      if (mounted) {
        setCurrentUser(user)
      }
      setPending(false)
    });
    
    return () => mounted = false;
  }, []);

  if(pending){
    return <Loading />
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
