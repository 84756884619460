import React, { useState } from 'react';
import { rtDatabase, fireAuth } from './../../../../../Fire';
import { Button, Modal, Form } from 'react-bootstrap';
import './UploadModal.css';

const UploadModal = props => {
    const [result, setResult] = useState({});

    const handleUploadChange = event => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.addEventListener('load', (e) => {
            let temp = JSON.parse(e.target.result);
            console.log(temp);
            setResult(temp);
        });
        reader.readAsText(file);
    }

    const handleConfirmUpload = event => {
        let updates = {}
        updates['userData/' + fireAuth.currentUser.uid + '/surveys/' + result.name] = result;
        rtDatabase.ref().update(updates).then(() => {
            console.log("uploaded file to database");
            window.location.reload();
        })
    }

    return (
        <Modal centered show={props.show} onHide={props.onHide} >
            <Modal.Header closeButton>
                <Modal.Title>Upload Survey JSON File</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="upload-modal-warning">
                    Warning: Make sure the uploaded file was 
                    downloaded from this same program and not corrupted 
                    in any way.
                </p>
                <Form>
                    <Form.Group>
                        <Form.File onChange={handleUploadChange} accept=".json" id="exampleFormControlFile1" label="Example file input" />
                    </Form.Group>
                    <div className="upload-confirm-btn-wrapper">
                        <Button onClick={handleConfirmUpload} variant="primary">Upload</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default UploadModal
