import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './Loading.css';

class Loading extends Component {
    render() {
        return (
            <div>
                <div data-testid="loading" className="auth-spinner-container">
                    <Spinner className="auth-spinner" animation="grow" variant="warning" />
                    <Spinner className="auth-spinner" animation="grow" variant="info" />
                    <Spinner className="auth-spinner" animation="grow" variant="success" />
                </div>
            </div>
        )
    }
}

export default Loading
