import React, { useEffect, useState } from 'react';
import { rtDatabase, fireAuth } from './../../../../../Fire';
import { Button, Modal, Form } from 'react-bootstrap';
import $ from 'jquery';

const RenameModal = props => {
    const [newName, setNewName] = useState('');
    const [nameValid, setNameValid] = useState({
        valid: true,
        feedback: ''
    })

    useEffect(() => {
        var input = $('input#survey-name-input');
        console.log(input)
        input.focus()
    }, [])
    
    const handleRenameChange = event => {
        setNewName(event.target.value)
        setNameValid({
            valid: true,
            feedback: ''
        })
        if (!validateName(event.target.value)) {
            setNameValid({
                valid: false,
                feedback: 'Please enter a valid name.'
            })
        }
    }

    const validateName = name => {
        let regex = /^[^\/'"]*$/
        return regex.test(name);
    }

    const handleSubmit = event => {
        event.preventDefault();
        if (validateForm()) {
            console.log("submitting");
            let uid = fireAuth.currentUser.uid;
            rtDatabase.ref('userData/' + uid + '/surveys/' + props.oldName).once('value').then(snapshot => {
                let surveyData = snapshot.val();
                surveyData.name = newName;
                let updates = {}
                updates['userData/' + uid + '/surveys/' + newName] = surveyData;
                updates['userData/' + uid + '/surveys/' + props.oldName] = null;
                rtDatabase.ref().update(updates).then(() => {
                    window.location.reload();
                })
            })
        }
    }

    const validateForm = () => {
        if (newName.length === 0) {
            setNameValid({
                valid: false,
                feedback: "Please enter a name."
            });
            return false;
        }
        if (!validateName(newName)) {
            setNameValid({
                valid: false,
                feedback: "Please enter a valid name."
            })
            return false;
        }
        return true;
    }

    return (
        <Modal centered show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className="bi-pencil" />
                    <span> Rename {props.oldName}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Control
                            className="rename-input-box"
                            value={newName}
                            onChange={handleRenameChange}
                            type="text"
                            placeholder="Enter New Name"
                            isInvalid={!nameValid.valid}
                            id='survey-rename-input'
                        />
                        <Form.Control.Feedback type='invalid'>{nameValid.feedback}</Form.Control.Feedback>
                    </Form.Group>
                    <Button type='submit' onClick={handleSubmit} variant='primary'>Submit</Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default RenameModal