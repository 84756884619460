import React, {Component} from 'react';
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';
import Homepage from "./components/pages/homepage/Homepage";
import LogIn from "./components/pages/log-in/LogIn";
import CreateAccount from "./components/pages/create-account/CreateAccount";
import CreateSurvey from './components/pages/create-survey/CreateSurvey';
import CountSurvey from './components/pages/count-survey/CountSurvey';
import Analysis from './components/pages/analysis/Analysis';
import Error from "./components/pages/error/Error";
import Bouncer from './components/pages/bouncer/Bouncer';
import Share from "./components/pages/share/Share";
import './App.css';
import { AuthProvider } from "./Auth";
import PrivateRoute from './PrivateRoute';

class App extends Component {

    render() {
        var routes = <div>
            {/* <ScrollToTop/> */}
            <Switch>
                <PrivateRoute path="/" component={Homepage} exact/>
                <PrivateRoute path="/create-survey" component={CreateSurvey} exact/>
                <PrivateRoute path="/count-survey" component={CountSurvey} exact/>
                <PrivateRoute path="/analysis" component={Analysis} exact/>
                <Route path="/bouncer" component={Bouncer} exact/>
                <Route path="/share/:uid/:surveyName/:shareName" component = {Share} exact/>
                <Route path="/log-in" component={LogIn} exact/>
                <Route path="/create-account" component={CreateAccount} exact/>
                <Route render={(props) => <Error {...props}/>}/>
            </Switch>
        </div>;
        
        if (window.cordova) {
            return (
                <div>
                    {/* This is to leave room for the top status bar in mobile apps */}
                    <div className={"app-header-cushion-top"}/>
                    <div className={"app-header-cushion-bottom"}/>
                    <AuthProvider value={this.state}>
                        <HashRouter basename={process.env.PUBLIC_URL} onUpdate={() => window.scrollTo(0,0)}>
                            {routes}
                        </HashRouter>
                    </AuthProvider>
                </div>
            );
        } else {
            return (
                <AuthProvider value={this.state}>
                    <BrowserRouter basename={process.env.PUBLIC_URL} onUpdate={() => window.scrollTo(0,0)}>
                        {routes}
                    </BrowserRouter>
                </AuthProvider>
            );
        }
    }
}

export default App;
