import React, { Component } from 'react';
import './NavBar.css';
import { fireAuth, rtDatabase } from '../../Fire';
import { Redirect } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';


class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userLoggedIn: false,
            userName: '',
            userPermissions: '',
            redirectTo: '',
            error: ''
        }
    }

    componentDidMount() {
        if (fireAuth.currentUser != null) {
            var uName = fireAuth.currentUser.displayName;
            var uid = fireAuth.currentUser.uid;
            rtDatabase.ref('users/' + uid).once('value').then(snapshot => {
                var user = snapshot.val();
                this.setState({
                    userPermissions: user.permissions,
                    userName: uName,
                    userLoggedIn: true
                });
            }).catch(error => {
                this.setState({error: error})
            })
        }
    }

    handleSignOut = event => {
        fireAuth.signOut().then(() => {
            console.log("signout successful");
            this.setState({userLoggedIn: false, userName: ''});
        }).catch(error => {
            console.log("Signout failed");
            console.log("error code: " + error.code);
            console.log("error message: " + error.message);
        })
    }

    getAdminDisplayClass = () => {
        if (this.state.userLoggedIn && this.state.userPermissions === 'Administrator') {
            return '';
        } else {
            return 'd-none';
        }
    }

    handleNavClick = event => {
        var item = event.target.id;
        if (this.props.pageLocation !== item) {
            // homepage's address is '/', not '/home'
            if (item === 'home') {
                item = '';
            }
            this.setState({
                redirectTo: '/' + item
            })
        }
    }

    render() {
        var signInLinkClass = '';
        var profileLinkClass = '';
        if (this.state.userLoggedIn) {
            signInLinkClass = 'd-none';
        } else {
            profileLinkClass = 'd-none';
        }

        if (this.state.error !== '') {
            console.log("We hit this weird error spot")
            return <Redirect to={{
                pathname: '/error',
                state: { code: this.state.error.code, message: this.state.error.message }
            }} />;
        }

        if (this.state.redirectTo !== '') {
            return <Redirect to={this.state.redirectTo} />;
        }
        return (
            <div>
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                    <Navbar.Brand href="/">
                        Survey Counter
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <div data-testid="admin-dropdown" className={this.getAdminDisplayClass()}>
                                <NavDropdown title="For Admins" id="collasible-nav-dropdown">
                                    <NavDropdown.Item id="users" onClick={this.handleNavClick}>Users</NavDropdown.Item>
                                    <NavDropdown.Item id="achievements" onClick={this.handleNavClick}>Achievements</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item id="help" onClick={this.handleNavClick}>Help (!!)</NavDropdown.Item>
                                </NavDropdown>
                            </div>
                        </Nav>
                        <Nav>
                            <Nav.Link id="log-in" className={signInLinkClass} onClick={this.handleNavClick}>Sign In</Nav.Link>
                            <div data-testid="profile-dropdown" className={profileLinkClass}>
                                <NavDropdown menualign="right" title="Profile" id="collapsible-nav-dropdown">
                                    <NavDropdown.Item onClick={this.handleSignOut}>Sign Out</NavDropdown.Item>
                                </NavDropdown>
                            </div>
                            <div data-testid="user-display-name" className={profileLinkClass + " navbar-profile-name"}>
                                <Navbar.Text >{this.state.userName}</Navbar.Text>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        )
    }
}

export default NavBar
