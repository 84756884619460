import React, { useState } from 'react';
import NavBar from './../../nav-bar/NavBar';
import Footer from './../../footer/Footer';
import { rtDatabase, fireAuth } from '../../../Fire';
import { Button, Form } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import './CreateSurvey.css';

const CreateSurvey = () => {
    const [redirect, setRedirect] = useState('')
    const [errorObj, seterrorObj] = useState('')
    const [surveyName, setSurveyName] = useState('');
    const [nameFeedback, setNameFeedback] = useState('')
    const [questions, setQuestions] = useState({
        "1": {
            question: '',
            type: 0,
        }
    });

    const handleNameChange = event => {
        setSurveyName(event.target.value);
        if (!validateName(event.target.value)) {
            setNameFeedback('Please enter a valid name.')
        } else if (nameFeedback.length > 0 && surveyName.length > 0) {
            setNameFeedback('')
        }
    }

    const handleQuestionChange = event => {
        let qnum = event.target.id;
        setQuestions({
            ...questions,
            [qnum]: {
                ...questions[qnum],
                question: event.target.value
            }
        });
    }

    const handleTypeChange = event => {
        var regex = /^(\d+)-(.+)$/
        var qnum = parseInt(event.target.id.replace(regex, '$1'))
        var type = event.target.id.replace(regex, '$2')
        setQuestions({
            ...questions,
            [qnum]: {
                ...questions[qnum],
                type: type
            }
        })
    }

    const handleAddQuestion = event => {
        let qnum = Object.keys(questions).length + 1;
        setQuestions({
            ...questions,
            [qnum]: {
                question: '',
                type: 0
            }
        })
    }

    const handleDeleteQuestion = event => {
        let newQList = {}
        Object.keys(questions).filter(qnum => {
            return parseInt(qnum) !== Object.keys(questions).length;
        }).forEach(qnum => {
            newQList[qnum] = questions[qnum];
        })
        setQuestions(newQList)
        console.log("length: " + Object.keys(questions).length)
    }

    const handleSubmit = event => {
        event.preventDefault()
        if (validateForm()) {
            let updates = {}
            updates['userData/' + fireAuth.currentUser.uid + '/surveys/' + surveyName] = {
                name: surveyName,
                entryCount: 0,
                questions: questions
            }
            rtDatabase.ref().update(updates).then(() => {
                setRedirect('/');
            }).catch(error => {
                seterrorObj(error)
            })
        }
    }

    const validateName = name => {
        let regex = /^[^\/'"]*$/
        return regex.test(name);
    }

    const validateForm = () => {
        if (surveyName.length === 0) {
            setNameFeedback('Please enter a survey name.');
            return false;
        }
        if (!validateName(surveyName)) {
            setNameFeedback('Please enter a valid name.')
            return false;
        }
        return true;
    }

    // Create question form renderables
    const questionDisplays = Object.keys(questions).map((qnum, i) => {
        let trashButton
        if (i === Object.keys(questions).length - 1) {
            trashButton = <div className="create-survey-trash-btn-wrapper">
                <Button onClick={handleDeleteQuestion} id={qnum} variant="secondary">
                    <i className="bi-trash"/>
                </Button>
            </div>
        } else {
            trashButton = <></>
        }
        return (
            <Form key={qnum}>
                <Form.Group>
                    <Form.Label>
                        <div className="question-input-header">
                            <p className="question-input-header-text">Question {qnum}</p>
                            {trashButton}
                        </div>
                    </Form.Label>
                    <Form.Control value={questions[qnum].question} onChange={handleQuestionChange} id={qnum} type="text" placeholder="Enter question" />
                </Form.Group>
                <div className="mb-3">
                    <Form.Check onChange={handleTypeChange} inline label="Ranking Question" name="group1" type="radio" id={qnum + '-Ranking'} />
                    <Form.Check onChange={handleTypeChange} inline label="Short Answer Question" name="group1" type="radio" id={qnum + '-Short Answer'} />
                </div>
            </Form>
        )
    })

    // redirect when done
    if (redirect.length > 0) {
        return <Redirect to={redirect}/>
    }

    // redirect to error screen if appropriate
    if (errorObj !== '') {
        return <Redirect to={{
            pathname: '/error',
            state: { code: errorObj.code, message: errorObj.message }
        }} />;
    }

    // main render
    return (
        <div>
            <NavBar/>
            <div className="create-survey-page-wrapper">
                <h1 className="create-survey-title">Create Survey</h1>
                <Form className="create-survey-form">
                    <Form>
                        <Form.Group>
                            <Form.Label>Survey Name</Form.Label>
                            <Form.Control isInvalid={nameFeedback.length > 0} required onChange={handleNameChange} value={surveyName} type="text" placeholder="Enter survey name"/>
                            <Form.Control.Feedback type="invalid">{nameFeedback}</Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                    {questionDisplays}
                    <div className="add-question-wrapper">
                        <Button onClick={handleAddQuestion} variant="primary">Add Question</Button>
                    </div>
                    <div className="add-question-wrapper">
                        <Button type="submit" onClick={handleSubmit} variant="dark">Submit New Survey</Button>
                    </div>
                </Form>
            </div>
            <Footer/>
        </div>
    )
}

export default CreateSurvey
