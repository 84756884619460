import firebase from 'firebase';

// will use firebase emulator if set to true
var useEmulator = true;

// Set the configuration for your app
// TODO: Replace with your project's config object
const firebaseConfig = {
    apiKey: "AIzaSyAg1y-oz3qIUPOySOBSp2pC0yZl7X4JCoA",
    authDomain: "survey-counter.firebaseapp.com",
    databaseURL: "https://survey-counter-default-rtdb.firebaseio.com",
    projectId: "survey-counter",
    storageBucket: "survey-counter.appspot.com",
    messagingSenderId: "125511479687",
    appId: "1:125511479687:web:fddc192eceaf04ac46a322",
    measurementId: "G-VHW5R6K7XT"
};
firebase.initializeApp(firebaseConfig);

export var rtDatabase = firebase.database();
export var fireAuth = firebase.auth();
if (window.location.hostname === "localhost" && useEmulator) {
    rtDatabase.useEmulator("localhost", 8001);
    fireAuth.useEmulator("http://localhost:9099");
} else {
    console.log("not using emulator.");
}

// Get a reference to the database service
// export var rtDatabase = firebase.database();
// export var fireAuth = firebase.auth();
