import * as EmailValidator from "email-validator";
import React, { Component } from "react";
import "./CreateAccount.css";
import { fireAuth, rtDatabase } from "../../../Fire";
import { Redirect } from "react-router-dom";
import Hash from "object-hash";

class CreateAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verified: false,
      firstNameValue: "",
      firstNameValid: "",
      lastNameValue: "",
      lastNameValid: "",
      emailValue: "",
      emailValid: "",
      emailError: "", // error to be shown for invalid or duplicate email
      passwordValue: "",
      passwordValid: "",
      passwordCheckValue: "",
      passwordCheckValid: "",
      doneRedirect: false,
      pageError: "",
    };
  }

  hasNumbers(t) {
    var regex = /\d/g;
    return regex.test(t);
  }

  hasBadChars(t) {
    var regex = /^(?!.*_)(?!.*\d)\w*$/g;
    return !regex.test(t);
  }

  isValidName(name) {
    // each condition returns true if the name doesn't have some character.
    // So all conditions must be true for function to return true.
    return (
      name.search(" ") === -1 &&
      name !== "" &&
      !this.hasNumbers(name) &&
      !this.hasBadChars(name)
    );
  }

  capitalizeName(name) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  handleFirstNameChange = (event) => {
    let newValue = this.capitalizeName(event.target.value);
    this.setState({ firstNameValue: newValue }, () => {
      if (this.isValidName(this.state.firstNameValue)) {
        this.setState({ firstNameValid: true }, this.verifyForm);
      } else {
        this.setState({ firstNameValid: false }, this.verifyForm);
      }
    });
  };

  handleLastNameChange = (event) => {
    let newValue = this.capitalizeName(event.target.value);
    this.setState({ lastNameValue: newValue }, () => {
      if (this.isValidName(this.state.lastNameValue)) {
        this.setState({ lastNameValid: true }, this.verifyForm);
      } else {
        this.setState({ lastNameValid: false }, this.verifyForm);
      }
    });
  };

  isValidEmail(email) {
    return EmailValidator.validate(email);
  }

  // isLetter(str) {
  //     return str.length === 1 && str.match(/[a-z]/i);
  // }

  // normalizeEmail(email) {
  //     for (var i = 0; i < email.length; i++) {
  //         if (this.isLetter(email.charAt(i))) {
  //             email.charAt(i).toLowerCase();
  //             console.log("hit letter");
  //         }
  //     }
  //     return email;
  // }

  handleEmailChange = (event) => {
    let newValue = event.target.value;
    this.setState({ emailValue: newValue }, () => {
      rtDatabase
        .ref("userEmailIndex")
        .once("value")
        .then((snapshot) => {
          if (snapshot.val()[Hash(newValue, { algorithm: "sha1" })] == null) {
            if (this.isValidEmail(this.state.emailValue)) {
              this.setState({ emailValid: true }, this.verifyForm);
            } else {
              this.setState(
                {
                  emailValid: false,
                  emailError: "Please provide a valid email address",
                },
                this.verifyForm
              );
            }
          } else {
            this.setState(
              {
                emailValid: false,
                emailError: "This email address is already taken",
              },
              this.verifyForm
            );
          }
        })
        .catch((error) => {
          this.setState({ pageError: error });
        });
    });
  };

  handlePasswordChange = (event) => {
    let newValue = event.target.value;
    this.setState({ passwordValue: newValue }, () => {
      if (
        this.state.passwordValue.length < 12 ||
        this.state.passwordValue.length > 32
      ) {
        this.setState({ passwordValid: false }, this.verifyForm);
      } else {
        this.setState({ passwordValid: true }, this.verifyForm);
      }
    });
  };

  handlePasswordCheckChange = (event) => {
    let newValue = event.target.value;
    this.setState({ passwordCheckValue: newValue }, () => {
      if (this.state.passwordCheckValue === this.state.passwordValue) {
        this.setState({ passwordCheckValid: true }, this.verifyForm);
      } else {
        this.setState({ passwordCheckValid: false }, this.verifyForm);
      }
    });
  };

  verifyForm = () => {
    if (
      this.state.firstNameValid &&
      this.state.lastNameValid &&
      this.state.emailValid &&
      this.state.passwordValid &&
      this.state.passwordCheckValid
    ) {
      this.setState({ verified: true });
    } else {
      this.setState({ verified: false });
    }
  };

  getDate = () => {
    var d = new Date();
    return {
      month: d.getMonth() + 1,
      day: d.getDate(),
      year: d.getFullYear(),
    };
  };

  handleSubmit = (event) => {
    fireAuth
      .createUserWithEmailAndPassword(
        this.state.emailValue,
        this.state.passwordValue
      )
      .then((user) => {
        // Signed in
        // ...
        var currentUser = fireAuth.currentUser;
        currentUser
          .updateProfile({
            displayName:
              this.state.firstNameValue + " " + this.state.lastNameValue,
          })
          .then(() => {
            // Add database entry for extra user information
            let updates = {};
            let userObj = {
              firstname: this.state.firstNameValue,
              lastname: this.state.lastNameValue,
              email: this.state.emailValue,
              dateAdded: this.getDate(),
              uid: currentUser.uid,
            };
            updates["users/" + currentUser.uid] = userObj;
            updates[
              "userEmailIndex/" + Hash(currentUser.email, { algorithm: "sha1" })
            ] = currentUser.email;
            updates["userData/" + currentUser.uid] = {
              name: this.state.firstNameValue + " " + this.state.lastNameValue,
            };
            rtDatabase
              .ref()
              .update(updates)
              .then(() => {
                this.setState({ doneRedirect: true });
              })
              .catch((error) => {
                // error with adding user info to database
                // delete account that was just created
                currentUser
                  .delete()
                  .then(() => {
                    this.setState({ pageError: error });
                  })
                  .catch((error2) => {
                    this.setState({ pageError: error2 });
                  });
              });

            //   rtDatabase.ref('userEmailIndex/' + Hash(currentUser.email, {algorithm: "sha1"})).set(currentUser.email).catch(error => {
            //     this.setState({pageError: error});
            //   })
            //   // add userData entry for user
            //   rtDatabase.ref('userData/' + currentUser.uid).set({
            //     name: this.state.firstNameValue + " " + this.state.lastNameValue
            //   }).catch(error => {
            //     this.setState({pageError: error})
            //   });
          })
          .catch((error) => {
            this.setState({ pageError: error });
          });
      })
      .catch((error) => {
        this.setState({ pageError: error });
      });
  };

  render() {
    if (this.state.doneRedirect) {
      return <Redirect to="/" />;
    }

    let firstNameValidClass = "";
    if (this.state.firstNameValid !== "") {
      firstNameValidClass = this.state.firstNameValid
        ? "is-valid"
        : "is-invalid";
    }
    let lastNameValidClass = "";
    if (this.state.lastNameValid !== "") {
      lastNameValidClass = this.state.lastNameValid ? "is-valid" : "is-invalid";
    }
    let emailValidClass = "";
    if (this.state.emailValid !== "") {
      emailValidClass = this.state.emailValid ? "is-valid" : "is-invalid";
    }
    let passwordValidClass = "";
    if (this.state.passwordValid !== "") {
      passwordValidClass = this.state.passwordValid ? "is-valid" : "is-invalid";
    }
    let passwordCheckValidClass = "";
    if (this.state.passwordCheckValid !== "") {
      passwordCheckValidClass = this.state.passwordCheckValid
        ? "is-valid"
        : "is-invalid";
    }

    if (this.state.pageError !== "") {
      return (
        <Redirect
          to={{
            pathname: "/error",
            state: {
              code: this.state.pageError.code,
              message: this.state.pageError.message,
            },
          }}
        />
      );
    } else {
      return (
        <div
          className="create-account-page" /* style={{backgroundImage: 'url(/img/music/journey3.jpg)'}}*/
        >
          <div className="create-account-card">
            <div
              id="signUpForm"
              className="create-account-card-wrapper form"
              noValidate
            >
              <h2 className="create-account-header">Create Account</h2>
              <p>Personal Information</p>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="addon-wrapping">
                    <i className="bi-person" />
                  </span>
                </div>
                <input
                  onChange={this.handleFirstNameChange}
                  value={this.state.firstNameValue}
                  type="name"
                  className={"form-control " + firstNameValidClass}
                  placeholder="first name"
                  aria-label="name"
                  aria-describedby="addon-wrapping"
                />
                <input
                  onChange={this.handleLastNameChange}
                  value={this.state.lastNameValue}
                  type="name"
                  className={"form-control " + lastNameValidClass}
                  placeholder="last name"
                  aria-label="name"
                  aria-describedby="addon-wrapping"
                />
                <div className="invalid-feedback">Enter a valid name</div>
              </div>
              <p className="create-account-lower-p">Login Credentials</p>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="addon-wrapping">
                    <i className="bi-envelope" />
                  </span>
                </div>
                <input
                  data-testid={"email " + emailValidClass}
                  onChange={this.handleEmailChange}
                  type="email"
                  id="emailInput"
                  className={"form-control " + emailValidClass}
                  placeholder="email"
                  aria-label="email"
                  aria-describedby="addon-wrapping"
                  required
                />
                <div className="invalid-feedback">{this.state.emailError}</div>
              </div>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="addon-wrapping">
                    <i className="bi-key" />
                  </span>
                </div>
                <input
                  onChange={this.handlePasswordChange}
                  type="password"
                  id="passwordInput"
                  className={"form-control " + passwordValidClass}
                  placeholder="password"
                  aria-label="password"
                  aria-describedby="addon-wrapping"
                  required
                />
                <div className="invalid-feedback">
                  Password must be between 12 and 32 characters
                </div>
              </div>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="addon-wrapping">
                    <i className="bi-key" />
                  </span>
                </div>
                <input
                  onChange={this.handlePasswordCheckChange}
                  type="password"
                  id="passwordInput"
                  className={"form-control " + passwordCheckValidClass}
                  placeholder="re-enter password"
                  aria-label="password"
                  aria-describedby="addon-wrapping"
                  required
                />
                <div className="invalid-feedback">Passwords do not match</div>
              </div>
              <div className="create-account-button-wrapper">
                <button
                  onClick={this.handleSubmit}
                  type="submit"
                  className="btn btn-primary btn-lg"
                  id="sign-up-button"
                  disabled={!this.state.verified}
                >
                  Sign Up
                </button>
              </div>
              <div id="create-account-already-have">
                <span>Already have an account?</span>
                <a href="/log-in">Log In</a>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default CreateAccount;
