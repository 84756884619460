import React, { Component } from 'react';
import NavBar from "./../../nav-bar/NavBar";
import Footer from "./../../footer/Footer";
import { rtDatabase, fireAuth } from './../../../Fire';
import { Redirect } from 'react-router';
import Loading from './../loading/Loading';
import { Button, Dropdown } from 'react-bootstrap';
import UploadModal from './components/upload-modal/UploadModal';
import HelpModal from './components/help-modal/HelpModal';
import RenameModal from './components/rename-modal/RenameModal';
import DuplicateModal from './components/duplicate-modal/DuplicateModal';
import Alert from './../../alert/Alert';
import './Homepage.css';


class Homepage extends Component {
    constructor(props) {
        super(props)
        var stateObj = {
            userData: '',
            currUid: '',
            error: '',
            loggedIn: false,
            redirect: '',
            surveyName: '',
            uploadObj: {},
            showUploadModal: false,
            showHelpModal: false,
            showRenameModal: false,
            showDuplicateModal: false,
            surveyOldName: '',
            showDeleteAlert: false,
            alertTitle: '',
            alertMessage: '',
            alertConfirmText: '',
            surveyDeleteName: '',
            redirectState: {}
        }
        if (fireAuth.currentUser !== null) {
            stateObj.currUid = fireAuth.currentUser.uid;
            stateObj.loggedIn = true
        }
        this.state = stateObj;
    }

    handleCreateSurvey = () => {
        this.setState({redirect: "/create-survey"})
    }

    handleCountSurvey = event => {
        console.log("id: " + event.target.id)
        console.log(event.target);
        this.setState({
            redirect: '/count-survey', 
            redirectState: {
                surveyName: event.target.id
            }
        })
    }

    handleAnalysis = event => {
        this.setState({
            redirect: '/analysis',
            redirectState: {
                surveyName: event.target.id,
                normalize: false
            }
        })
    }

    download(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
      
        element.style.display = 'none';
        document.body.appendChild(element);
      
        element.click();
      
        document.body.removeChild(element);
    }

    handleSurveyRename = event => {
        console.log('renaming: ' + event.target.id);
        this.setState({
            showRenameModal: true,
            surveyOldName: event.target.id
        })
    }

    handleDuplicateSurvey = event => {
        console.log('duplicating: ' + event.target.id);
        this.setState({
            showDuplicateModal: true,
            surveyOldName: event.target.id
        })
    }

    handleDownload = event => {
        let name = event.target.id;
        console.log(this.state.userData.surveys[name])
        let objStr = JSON.stringify(this.state.userData.surveys[name])
        this.download(name + ".json",objStr);
    }

    handleDeleteBtn = event => {
        this.setState({
            showDeleteAlert: true,
            alertTitle: <div>
                <i className='bi-trash'/>
                <span> Delete {event.target.id}</span>
            </div>,
            alertMessage: "Are you sure you want to delete \"" + event.target.id + "\"? This action cannot be undone. " +
                          "You may want to download a copy first.",
            alertConfirmText: "Delete " + event.target.id,
            surveyDeleteName: event.target.id
        })
    }

    handleConfirmDelete = name => {
        console.log("deleting " + this.state.surveyDeleteName);
        let updates = {}
        updates['userData/' + fireAuth.currentUser.uid + '/surveys/' + this.state.surveyDeleteName] = null;
        rtDatabase.ref().update(updates).then(() => {
            console.log("deleted");
            window.location.reload();
        })
    }

    handleShowModal = stateProp => {
        this.setState({
            [stateProp]: true
        })
    }

    handleHideModal = stateProp => {
        this.setState({
            [stateProp]: false
        })
    }

    componentDidMount() {
        if (fireAuth.currentUser !== null) {
            rtDatabase.ref('userData/' + fireAuth.currentUser.uid).once('value').then(snapshot => {
                this.setState({userData: snapshot.val()})
            })
        }
    }

    render() {

        let surveys;
        if (this.state.userData.surveys === undefined) {
            surveys = <></>
        } else {
            surveys = Object.keys(this.state.userData.surveys).map(surveyName => {
                return (
                    <div key={surveyName} className="survey-item-container">
                        <p className="homepage-survey-name">{surveyName} - Count: {this.state.userData.surveys[surveyName].entryCount}</p>
                        <div className="homepage-survey-buttons">
                            <div className="homepage-survey-btn-wrapper">
                                <Button className="btn-has-screen" id={surveyName} onClick={this.handleCountSurvey} variant="primary">
                                    <div className="btn-screen" title="Count Survey" id={surveyName} />
                                    <i className="bi-calculator"/>
                                </Button>
                            </div>
                            <div className="homepage-survey-btn-wrapper">
                                <Button className="btn-has-screen" id={surveyName} onClick={this.handleAnalysis} variant="success">
                                    <div className="btn-screen" title="Show Results" id={surveyName} />
                                    <i className="bi-bar-chart-line"/>
                                </Button>
                            </div>
                            {/* <div className="homepage-survey-btn-wrapper">
                                <Button className="btn-has-screen" id={surveyName} onClick={this.handleDownload} variant="secondary">
                                    <div className="btn-screen" title="Download Survey" id={surveyName} />
                                    <i className="bi-cloud-download"/>
                                </Button>
                            </div>
                            <div className="homepage-survey-btn-wrapper">
                                <Button className="btn-has-screen" id={surveyName} onClick={this.handleDeleteBtn} variant="danger">
                                    <div className="btn-screen" title="Delete Survey" id={surveyName} />
                                    <i className="bi-trash"/>
                                </Button>
                            </div> */}
                            <div className="homepage-survey-btn-wrapper">
                                <Dropdown>
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                        <i className='bi-three-dots'/>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={this.handleSurveyRename} id={surveyName}>
                                            <i className='bi-pencil' id={surveyName}/>
                                            <span id={surveyName}> Rename</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={this.handleDuplicateSurvey} id={surveyName}>
                                            <i className='bi-file-earmark-plus' id={surveyName}/>
                                            <span id={surveyName}> Duplicate</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={this.handleDownload} id={surveyName}>
                                            <i className='bi-cloud-download' id={surveyName}/>
                                            <span id={surveyName}> Download Data</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={this.handleDeleteBtn} id={surveyName}>
                                            <i className='bi-trash' id={surveyName}/>
                                            <span id={surveyName}> Delete Survey</span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                )
            })
        }

        if (this.state.redirect !== '') {
            return <Redirect to={{
                pathname: this.state.redirect,
                state: this.state.redirectState
            }} />;
        }

        if (this.state.error !== '') {
            return <Redirect to={{
                pathname: '/error',
                state: { code: this.state.error.code, message: this.state.error.message }
            }} />;
        } else if (this.state.loggedIn && this.state.userData === '') {
            return (
                <div>
                    <Loading/>
                </div>
            )
        } else {
            return(
                <div>
                    <NavBar />
                    <div data-testid="homepage-content" className="page-content">
                        <div className="homepage-window-container">
                            <h1 className="homepage-title">Your Surveys</h1>
                            <div className="survey-container">
                                {surveys}
                            </div>
                            <div className="create-survey-btn-wrapper">
                                <Button onClick={this.handleCreateSurvey} variant="dark">Create Survey</Button>
                            </div>
                            <div className="create-survey-btn-wrapper">
                                <Button onClick={() => {this.handleShowModal("showUploadModal")}} variant="secondary">Upload Survey</Button>
                            </div>
                            <div className="create-survey-btn-wrapper homepage-help-btn-wrapper">
                                <Button onClick={() => {this.handleShowModal("showHelpModal")}} variant="info">Get Help</Button>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <UploadModal
                        onHide={() => { this.handleHideModal("showUploadModal")}}
                        show={this.state.showUploadModal}
                    />
                    <HelpModal
                        onHide={() => { this.handleHideModal("showHelpModal")}}
                        show={this.state.showHelpModal}
                    />
                    <RenameModal
                        onHide={() => { this.handleHideModal("showRenameModal")}}
                        show={this.state.showRenameModal}
                        oldName={this.state.surveyOldName}
                    />
                    <DuplicateModal
                        onHide={() => { this.handleHideModal("showDuplicateModal")}}
                        show={this.state.showDuplicateModal}
                        oldName={this.state.surveyOldName}
                    />
                    <Alert
                        actionNeeded={true}
                        show={this.state.showDeleteAlert}
                        title={this.state.alertTitle}
                        message={this.state.alertMessage}
                        confirmText={this.state.alertConfirmText}
                        variant="danger"
                        cancelCallback={() => {this.handleHideModal("showDeleteAlert")}}
                        confirmCallback={this.handleConfirmDelete}
                    />
                </div>
            )
        }
    }
}

export default Homepage
