import React, { useState, useEffect } from 'react';
import NavBar from './../../nav-bar/NavBar';
import Footer from './../../footer/Footer';
import Loading from '../loading/Loading';
import { rtDatabase, fireAuth } from '../../../Fire';
import { Form, Button } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import './CountSurvey.css'

const CountSurvey = props => {
    const surveyName = props.location.state.surveyName;
    const [questions, setQuestions] = useState('');
    const [entries, setEntries] = useState('');
    const [entryCount, setEntryCount] = useState('');
    const [errorObj, setErrorObj] = useState('');
    const [redirect, setRedirect] = useState('');

    useEffect(() => {
        console.log("running use effect");
        rtDatabase.ref('userData/' + fireAuth.currentUser.uid + '/surveys/' + surveyName).once('value').then(snapshot => {
            let qList = snapshot.val().questions;
            let temp = {}
            Object.keys(qList).forEach(qnum => {
                if (qList[qnum].type === 'Short Answer') {
                    temp[qnum] = [''];
                } else {
                    temp[qnum] = '';
                }
            })
            setEntries(temp);
            setQuestions(qList);
            setEntryCount(snapshot.val().entryCount);
        })
    }, [])

    const handleRankChange = event => {
        let qnum = event.target.id;
        const regex = /^[\d-]?$/;
        const isValid = regex.test(event.target.value);
        if (isValid) {
            let newVal = event.target.value === '0' ? '10' : event.target.value === '-' ? '11' : event.target.value
            setEntries({
                ...entries,
                [qnum]: newVal
            })
        }
        if (event.target.value.length > 0 && isValid) {
            var input = $('input#' + (parseInt(qnum) + 1));
            input.focus()
        }
    }

    const handleShortAnswerChange = event => {
        let regex = /^(\d+)-(\d+)$/;
        let qnum = event.target.id.replace(regex, '$1');
        let aindex = event.target.id.replace(regex, '$2');
        let temp = entries[qnum];
        temp[aindex] = event.target.value;
        if (temp[parseInt(aindex) + 1] === undefined) {
            temp[parseInt(aindex) + 1] = '';
        }
        setEntries({
            ...entries,
            [qnum]: temp
        })
    }

    const resetForm = () => {
        let temp = {}
        Object.keys(questions).forEach(qnum => {
            if (questions[qnum].type === 'Short Answer') {
                temp[qnum] = [''];
            } else {
                temp[qnum] = '';
            }
        })
        setEntries(temp);
        // setEntryCount(entryCount + 1);
        var topinput = $('input#1');
        topinput.focus()
    }

    const handleSubmitEntry = event => {
        event.preventDefault();

        rtDatabase.ref('userData/' + fireAuth.currentUser.uid + '/surveys/' + surveyName + '/entryCount').once('value').then(snapshot => {
            let currCount = snapshot.val();
            setEntryCount(currCount + 1);

            let updates = {}
            let uid = fireAuth.currentUser.uid
            let stagedEntries = entries;
            Object.keys(stagedEntries).forEach(qnum => {
                if (Array.isArray(stagedEntries[qnum]) && stagedEntries[qnum].length > 1) {
                    stagedEntries[qnum].pop();
                }
            })
            updates['userData/' + uid + '/surveys/' + surveyName + '/entries/' + (currCount + 1)] = stagedEntries
            updates['userData/' + uid + '/surveys/' + surveyName + '/entryCount'] = currCount + 1;
            rtDatabase.ref().update(updates).then(() => {
                resetForm()
            }).catch(error => {
                setErrorObj(error)
            })
        })


    }

    const handleGoHome = event => {
        setRedirect('/')
    }

    // loading
    if (questions === '' || entries === '' || entryCount === '') {
        return <Loading />
    }

    // redirect to error screen if appropriate
    if (errorObj !== '') {
        return <Redirect to={{
            pathname: '/error',
            state: { code: errorObj.code, message: errorObj.message }
        }} />;
    }

    if (redirect !== '') {
        return <Redirect to={redirect}/>
    }

    return (
        <div>
            <NavBar/>
            <div className="count-survey-page-wrapper">
                <div className="count-survey-header">
                    <h1 className="count-survey-title">{surveyName}</h1>
                    <p className="count-survey-p">Surveys Counted: {entryCount}</p>
                    <p className="count-survey-p">Instructions</p>
                    <ul>
                        <li>
                            <p className="count-survey-p">Press 'Enter' to quickly submit entry.</p>
                        </li>
                        <li>
                            <p className="count-survey-p">For 'Number' questions, enter 1-9 or '0' for 10 and '-' for 11.</p>
                        </li>
                    </ul>
                </div>
                <Form className="count-survey-form">
                    {Object.keys(questions).map(qnum => {
                        if (questions[qnum].type === 'Ranking') {
                            return (
                                <div key={qnum}>
                                    <Form.Group>
                                        <Form.Label>
                                            {questions[qnum].question}
                                        </Form.Label>
                                        <Form.Control
                                            className="ranking-input-box"
                                            value={entries[qnum] === undefined ? '' : entries[qnum]}
                                            onChange={handleRankChange}
                                            id={qnum}
                                            type="text"
                                            placeholder="Number"
                                        />
                                    </Form.Group>
                                </div>
                            )
                        } else if (questions[qnum].type === 'Short Answer') {
                            return (
                                <div key={qnum}>
                                    <Form.Group>
                                        <Form.Label>
                                            {questions[qnum].question}
                                        </Form.Label>
                                        {entries[qnum].map((item, i) => {
                                            return (
                                                <Form.Control
                                                    className="short-answer-input-box"
                                                    value={entries[qnum] === undefined ? '' : entries[qnum][i]}
                                                    onChange={handleShortAnswerChange}
                                                    id={qnum + "-" + i}
                                                    type="text"
                                                    placeholder="Short Answer"
                                                />
                                            )
                                        })}
                
                                    </Form.Group>
                                </div>
                            )
                        }
                    })}
                    <div className="count-survey-btns">
                        <div className="count-survey-submit-btn-wrapper">
                            <Button onClick={handleSubmitEntry} type="submit" variant="primary">Submit Entry</Button>
                        </div>
                        <div className="count-survey-home-btn-wrapper">
                            <Button onClick={handleGoHome} variant="dark">Go Home</Button>
                        </div>
                    </div>
                </Form>
            </div>
            <Footer />
        </div>
    )
}

export default CountSurvey