import React, { Component } from 'react';
import {Chart} from 'chart.js';

class BarChart extends Component {
    chartRef = React.createRef();

    constructor(props) {
        super(props);
        this.max = this.props.type === 'Ranking' && this.props.scale ? this.props.max : undefined;
    }
    
    componentDidMount() {
        this.buildChart();
    }

    buildChart = () => {
        const myChartRef = this.chartRef.current.getContext("2d");
        
        new Chart(myChartRef, {
            type: "bar",
            data: {
                //Bring in data
                labels: this.props.labels,
                datasets: [
                    {
                        label: this.props.title,
                        data: this.props.data,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: 'rgba(0,0,0,1)',
                        borderWidth: 2,
                    }
                ]
            },
            options: {
                //Customize chart options
                scales: {
                    y: {
                        beginAtZero: true,
                        min: 0,
                        max: this.max
                    }
                } 
            }
        });
    }

    render() {
        return (
            <div className={"huh"}>
                <canvas
                    id="myChart"
                    ref={this.chartRef}
                />
            </div>
        )
    }
}

export default BarChart
