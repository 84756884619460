import React, { useState } from 'react';
import { rtDatabase, fireAuth } from './../../../../../Fire';
import { Modal, Carousel} from 'react-bootstrap';
import './HelpModal.css';

const HelpModal = props => {

    return (
        <Modal centered show={props.show} onHide={props.onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Help</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Carousel>
                <Carousel.Item interval={25000}>
                        <div className="help-carousel-container">
                            <div className="help-gif-wrapper">
                                <img className="help-gif" src="https://i.imgur.com/35Ht5iI.gif" />
                            </div>
                            <Carousel.Caption>
                                <h3>Create Survey</h3>
                                <p>Add questions and specify question types for a new survey.</p>
                            </Carousel.Caption>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item interval={36000}>
                        <div className="help-carousel-container">
                            <div className="help-gif-wrapper">
                                <img className="help-gif" src="https://i.imgur.com/sAOgPXM.gif" />
                            </div>
                            <Carousel.Caption>
                                <h3>Count Surveys</h3>
                                <p>
                                    Quickly count ranking and short answer questions and simply
                                    press 'enter' to save an entry and move on to the next.
                                </p>
                            </Carousel.Caption>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item interval={16000}>
                        <div className="help-carousel-container">
                            <div className="help-gif-wrapper">
                                <img className="help-gif" src="https://i.imgur.com/7zPqzAm.gif" />
                            </div>
                            <Carousel.Caption>
                                <h3>Analysis</h3>
                                <p>View insightful charts breaking down each survey question.</p>
                            </Carousel.Caption>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item interval={24000}>
                        <div className="help-carousel-container">
                            <div className="help-gif-wrapper">
                                <img className="help-gif" src="https://i.imgur.com/GaKz2TR.gif" />
                            </div>
                            <Carousel.Caption>
                                <h3>Share Data</h3>
                                <p>
                                    Easily download a survey's data and upload it to another account,
                                    or upload another account's data to yours.
                                </p>
                            </Carousel.Caption>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </Modal.Body>
        </Modal>
    )
}

export default HelpModal