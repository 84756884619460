import React from 'react';
import { useParams } from 'react-router-dom';
import Analysis from './../analysis/Analysis';

const Share = props => {
    let { uid } = useParams();
    let { surveyName } = useParams();
    let { shareName } = useParams();
    const normalize = props.location.state === undefined ? false : props.location.state.normalize;

    const fixName = name => {
        return name.replace(/%20/g, ' ');
    }
    const newName = fixName(surveyName);
    const newShareName = fixName(shareName);

    return (
        <div>
            <Analysis
                uid={uid}
                surveyName={newName}
                sharing={true}
                shareName={newShareName}
                normalize={normalize}
                bouncePath={window.location.pathname}
            />
        </div>
    )
}

export default Share;