import React, {useState} from 'react';
import { Modal, Button } from 'react-bootstrap';
import './Alert.css';

/*
    Props - (1) if action needed - (2) whether or not action needed
    -----------
    actionNeeded: boolean indicating if confirmation action is required
    (2) show: boolean indicating if the modal should be shown
    (2) title: title for the alert modal
    (2) message: message for the alert modal
    (1) variant: indicates color of confirmation button
    (1) confirmText: text that goes in the confirm button
    (2) confirmCallback: function that is called when confirmation button is clicked
    (1) cancelCallback: function that is called when action is cancelled
*/
function Alert(props) {
    const [confirmField, setConfirmField] = useState('');

    const handleConfirmCodeChange = event => {
        setConfirmField(event.target.value)
    }

    if (props.confirmationNeeded) {
        return (
            <div>
                <Modal centered show={props.show} onHide={props.cancelCallback} backdrop="static" keyboard={false}>
                    <Modal.Header >
                        <Modal.Title>{props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {props.message}
                        <p className="alert-confirm-code-instructions">
                            Type '{props.confirmCode}' to delete the group.
                        </p>
                        <input type="text" className="form-control" placeholder={props.confirmCode} onChange={handleConfirmCodeChange} value={confirmField} />
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button variant="secondary" onClick={() => {
                                setConfirmField('');
                                props.cancelCallback();
                            }}>
                                Cancel
                            </Button>
                        </div>
                        <div>
                            <Button variant={props.variant} onClick={props.confirmCallback} disabled={confirmField !== props.confirmCode}>
                                {props.confirmText}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    } else if (props.actionNeeded) {
        return (
            <div>
                <Modal centered show={props.show} onHide={props.cancelCallback} backdrop="static" keyboard={false}>
                    <Modal.Header >
                        <Modal.Title>{props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{props.message}</Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button variant="secondary" onClick={props.cancelCallback}>
                                Cancel
                            </Button>
                        </div>
                        <div>
                            <Button variant={props.variant} onClick={props.confirmCallback}>
                                {props.confirmText}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    } else {
        return (
            <div>
                <Modal centered show={props.show} onHide={props.confirmCallback}>
                    <Modal.Header closeButton>
                        <Modal.Title>{props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{props.message}</Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button variant="secondary" onClick={props.confirmCallback}>
                                Close
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default Alert
